import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import HomePage from './Paginas/HomePage'
import QuemSomos from './Paginas/QuemSomos'
import Galeria from './Paginas/Galeria'
import Contactos from './Paginas/Contactos'
import Barco from './Paginas/Barco'
import Marcacoes from './Paginas/Marcacoes'
import Colaboradores from "./Paginas/Colaboradores";

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/QuemSomos" component={QuemSomos} />
        <Route path="/Galeria" component={Galeria} />
        <Route path="/Contactos" component={Contactos} />
        <Route path="/Barco" component={Barco} />
        <Route path="/Marcacoes" component={Marcacoes} />
        <Route path="/Colaboradores" component={Colaboradores} />
        <Route path="*" component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default RouterComponent;