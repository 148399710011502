import './Galeria.css'
import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'
import GalleryComp from '../Componentes/GalleryComp'

import { imgsGaleria } from '../Data'

const Galeria = () => {
    return (
        <div className="MainDiv">
            <TopBar />
            <MenuBar />
            <GalleryComp PhotoArray={imgsGaleria} />
            <BottomBar />
        </div>
    )
}

export default Galeria;