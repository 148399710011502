import './Colaboradores.css'

import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'

import FronteiraLogo from '../Media/LogoFronteira.png'
import CasaCaracoisLogo from '../Media/CasaCaracois.jpg'

const Colaboradores = () => {
    return (
        <div className="MainDiv">
            <TopBar />
            <MenuBar />
            <div className='SponsorDivOut'>
                <div className='SponsorDivIn'>
                    <img onClick={() => window.location.replace('https://www.fronteira-amostras.com')} className='SponsorImg' src={FronteiraLogo} alt='MissingImage' />
                    <img className='SponsorImgCara' src={CasaCaracoisLogo} alt='MissingImage' />
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Colaboradores;