import './HomePage.css';
import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'
import mainPhoto from '../Media/Tshirt.jpg'
import imgAlt from '../Media/LogoFronteira.png'

const HomePage = () => {
  return (
    <div className="backGround">
      <TopBar />
      <MenuBar />
      <div className='mainPhotoDiv'>
        <img src={mainPhoto} alt={imgAlt} className='mainPhoto'></img>
      </div>
      <BottomBar />
    </div>
  );
}

export default HomePage;
