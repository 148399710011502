import './QuemSomos.css'
import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'

import { useTranslation } from "react-i18next";

const QuemSomos = () => {
    const { t } = useTranslation('common');
    return (
        <div className="MainDiv">
            <TopBar />
            <MenuBar />
            <div className='QuemSomosTextTitulo'>
                <span>
                    {t('WhoWeAre.Text1')}
                </span>
            </div>
            <div className='QuemSomosText'>
                <span>
                    {t('WhoWeAre.Text2')}
                </span>
            </div>
            <div className='QuemSomosText'>
                
            </div>
            <div className='QuemSomosText'>
                
            </div>
            <div className='QuemSomosTextFim'>
                <span>
                    {t('WhoWeAre.Text3')}
                </span>
            </div>
            <BottomBar />
        </div>
    )
}

export default QuemSomos;