import './GalleryComp.css'

import { useState } from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';

import GalLeft from '../Media/GalLeft.png'
import GalRight from '../Media/GalRight.png'
import GalCross from '../Media/GalCross.png'

const GalleryComp = (props) => {
    let [openModal, setOpenModal] = useState(false);
    let [modalImg, setModalImg] = useState();
    let [modalImgId, setModalImgId] = useState();

    const handleMediaClick = (el) => {
        setModalImg(el.img);
        setOpenModal(!openModal);
        setModalImgId(el.id);
    }

    const handleNextPicture = () => {
        if ((modalImgId - 1) < props.PhotoArray.length) {
            props.PhotoArray.forEach(el => {
                if (el.id === modalImgId + 1) {
                    setModalImg(el.img);
                    setModalImgId(el.id);
                }
            });
        }
    }

    const handlePreviousPicture = () => {
        if (modalImgId > 1) {
            props.PhotoArray.forEach(el => {
                if (el.id === modalImgId - 1) {
                    setModalImg(el.img);
                    setModalImgId(el.id);
                }
            });
        }
    }

    return (
        <div className='GaleriaDiv'>
            {props.PhotoArray.map(el =>
                <Card className='GaleriaImgOut' key={el.id}>
                    <CardActionArea>
                        <CardMedia className='GaleriaCardMedia' image={el.img} onClick={() => handleMediaClick(el)} />
                    </CardActionArea>
                </Card>
            )}
            <Modal className='GaleriaModal' open={openModal} onClose={() => setOpenModal(!openModal)}>
                <div className='GaleriaModalDiv'>
                    <div className='GaleriaModalCross'><IconButton><img className='GaleriaModalImg' onClick={() => setOpenModal(!openModal)} src={GalCross} alt='MissingImage'></img></IconButton></div>
                    <div className='GaleriaModalNextBtn'><IconButton><img className='GaleriaModalImgSides' onClick={() => handleNextPicture()} src={GalRight} alt='MissingImage'></img></IconButton></div>
                    <div className='GaleriaModalPreviousBtn'><IconButton><img className='GaleriaModalImgSides' onClick={() => handlePreviousPicture()} src={GalLeft} alt='MissingImage'></img></IconButton></div>
                    <img className='ModalImg' src={modalImg} alt='MissingImage'></img>
                </div>
            </Modal>
        </div>
    )
}

export default GalleryComp;