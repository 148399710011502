import './BottomBar.css'
import bottomBarLeftImg from '../Media/Clean&Safe1.png'
import bottomBarRightImg from '../Media/Clean&Safe2.png'

import Grid from '@material-ui/core/Grid';

import { useTranslation } from "react-i18next";

const BottomBar = () => {
    const { t } = useTranslation('common');
    return (
        <Grid container className="BottomBar">
            <Grid item xs={12} md={4}>
                <div className="bottomBarLeft">
                    <img src={bottomBarLeftImg} alt={'MissingImg'} className='bottomBarLeftImg'></img>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className='BottomBarText'>
                    {t('BottomBar.AllRights')}
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className='bottomBarRight'>
                    <img src={bottomBarRightImg} alt={'MissingImg'} className='bottomBarRightImg'></img>
                </div>
            </Grid>
        </Grid>
    )
}

export default BottomBar;