import './Marcacoes.css'
import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import imgAlt from '../Media/LogoFronteira.png'

import { imgsPeixes } from '../Data'

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Marcacoes = () => {
    const { t } = useTranslation('common');
    let history = useHistory();
    const trips = Object.entries(t('Trips'));


    return (
        <div className="MainDiv">
            <TopBar></TopBar>
            <MenuBar></MenuBar>
            <div className='MarcacoesCardDivOut'>
                <div className='MarcacoesCardDiv'>
                    {trips.map(el =>
                        <Card className='MarcacoesCard' key={el[1].id}>
                            <CardContent className='MarcacoesCardContent'>
                                <div className='peixePhotoDiv'>
                                    <img src={imgsPeixes[el[1].id].img} alt={imgAlt} className='peixePhoto'></img>
                                </div>
                                <span className='MarcacoesCardTitle'>{el[1].name}</span>
                                <Typography className='MarcacoesCardTypography2' paragraph>
                                    {el[1].descrip}
                                </Typography>
                                <div className='infoContacto'>
                                    <div className='infoContactoIn'>
                                        <Button className='MarcacoesCardReserva' variant='outlined' onClick={() => history.push('/Contactos')} size="small" color="primary">
                                            {el[1].cardBtn}
                                        </Button>
                                    </div>
                                    <span className='infoContactoIn'>
                                        {el[1].infoContacto}
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Marcacoes