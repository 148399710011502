import './Barco.css'
import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'

import GalleryComp from '../Componentes/GalleryComp'

import { imgsBarco } from '../Data'

import { useTranslation } from "react-i18next";

const Barco = () => {
    const { t } = useTranslation('common');

    return (
        <div className="MainDiv">
            <TopBar />
            <MenuBar />
            <div className='BarcoTexto'>
                {t('Boat.Text1')}
            </div>
            <div className='BarcoTexto'>
                {t('Boat.Text2')}
            </div>
            <div className='BarcoTexto'>
                {t('Boat.Text3')}
            </div>
            <GalleryComp PhotoArray={imgsBarco} />
            <BottomBar />
        </div>
    )
}

export default Barco;