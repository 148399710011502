import imgGal1 from './Media/Galeria/1.jpg'
import imgGal2 from './Media/Galeria/3.jpg'
import imgGal3 from './Media/Galeria/4.jpg'
import imgGal4 from './Media/Galeria/5.jpg'
import imgGal5 from './Media/Galeria/6.jpg'
import imgGal6 from './Media/Galeria/7.jpg'
import imgGal7 from './Media/Galeria/8.jpg'
import imgGal8 from './Media/Galeria/10.jpg'
import imgGal9 from './Media/Galeria/11.jpg'
import imgGal10 from './Media/Galeria/13.jpg'
import imgGal11 from './Media/Galeria/14.jpg'
import imgGal12 from './Media/Galeria/15.jpg'
import imgGal13 from './Media/Galeria/16.jpg'
import imgGal14 from './Media/Galeria/17.jpg'
import imgGal15 from './Media/Galeria/19.jpg'

import imgBarco1 from './Media/Barco/1.jpg'
import imgBarco2 from './Media/Barco/2.jpg'
import imgBarco3 from './Media/Barco/3.jpg'
import imgBarco4 from './Media/Barco/4.jpg'
import imgBarco5 from './Media/Barco/5.jpg'
import imgBarco6 from './Media/Barco/6.jpg'
import imgBarco7 from './Media/Barco/7.jpg'
import imgBarco8 from './Media/Barco/8.jpg'
import imgBarco9 from './Media/Barco/9.jpg'
import imgBarco10 from './Media/Barco/10.jpg'
import imgBarco11 from './Media/Barco/11.jpg'

import imgPeixe1 from './Media/marlinAzul.jpg'
import imgPeixe2 from './Media/atum.jpg'
import imgPeixe3 from './Media/marlinBranco.jpg'
import imgPeixe4 from './Media/doirado.jpg'

const imgsGaleria = [
    {
        id: 1,
        img: imgGal1
    },
    {
        id: 2,
        img: imgGal2
    },
    {
        id: 3,
        img: imgGal3
    },
    {
        id: 4,
        img: imgGal4
    },
    {
        id: 5,
        img: imgGal5
    },
    {
        id: 6,
        img: imgGal6
    },
    {
        id: 7,
        img: imgGal7
    },
    {
        id: 8,
        img: imgGal8
    },
    {
        id: 9,
        img: imgGal9
    },
    {
        id: 10,
        img: imgGal10
    },
    {
        id: 11,
        img: imgGal11
    },
    {
        id: 12,
        img: imgGal12
    },
    {
        id: 13,
        img: imgGal13
    },
    {
        id: 14,
        img: imgGal14
    },
    {
        id: 15,
        img: imgGal15
    }]

const imgsBarco = [
    {
        id: 1,
        img: imgBarco1
    },
    {
        id: 2,
        img: imgBarco2
    },
    {
        id: 3,
        img: imgBarco3
    },
    {
        id: 4,
        img: imgBarco4
    },
    {
        id: 5,
        img: imgBarco5
    },
    {
        id: 6,
        img: imgBarco6
    },
    {
        id: 7,
        img: imgBarco7
    },
    {
        id: 8,
        img: imgBarco8
    },
    {
        id: 9,
        img: imgBarco9
    },
    {
        id: 10,
        img: imgBarco10
    },
    {
        id: 11,
        img: imgBarco11
    }]
    const imgsPeixes = [
        {
            id: 1,
            img: imgPeixe1
        },
        {
            id: 2,
            img: imgPeixe2
        },
        {
            id: 3,
            img: imgPeixe3
        },
        {
            id: 4,
            img: imgPeixe4
        }]

export { imgsGaleria };
export { imgsBarco };
export { imgsPeixes }