import React from 'react'

import './TopBar.css'
import imgAlt from '../Media/LogoFronteira.png'
import img1 from '../Media/imgTopLeft2.png'
import img2 from '../Media/TopCenter.png'

import Grid from '@material-ui/core/Grid';

import { useHistory } from "react-router-dom";

const TopBar = () => {
    let history = useHistory();

    return (
        <Grid container className="TopBar">
            <Grid item xs={3}>
                <div className="divL">
                    <img onClick={() => history.push('/')} className='leftImg' src={img1} alt={'MissingImg'}></img>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className='centerImg'>
                    <img className='centerImgIn' src={img2} alt={'MissingImg'}></img>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className='logoFronteiraDiv'>
                    <img onClick={() => window.open('https://www.fronteira-amostras.com')} className='logoFronteira' src={imgAlt} alt={'MissingImg'}></img>
                </div>
            </Grid>
        </Grid>
    );
};

export default TopBar;