import './MenuBar.css'
import PT from '../Media/PT.png'
import ENG from '../Media/ENG.png'
import imgFacebook from '../Media/LogoFace.jpg'
import imgInstagram from '../Media/LogoInsta.png'

import IconButton from '@material-ui/core/IconButton';

import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import { useTranslation } from "react-i18next";

const MenuBar = (props) => {
    const { t, i18n } = useTranslation('common');
    let history = useHistory();
    return (
        <div className='MenuBar'>
            <Grid container>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item className='MenuBarButtonGridItem'>
                            <div onClick={() => history.push("/QuemSomos")} className='menuBarBtn'>{t('MenuBar.WhoWeAre')}</div>
                        </Grid>
                        <Grid item className='MenuBarButtonGridItem'>
                            <div onClick={() => history.push("/Galeria")} className='menuBarBtn'>{t('MenuBar.Galery')}</div>
                        </Grid>
                        <Grid item className='MenuBarButtonGridItem'>
                            <div onClick={() => history.push("/Contactos")} className='menuBarBtn'>{t('MenuBar.Contact')}</div>
                        </Grid>
                        <Grid item className='MenuBarButtonGridItem'>
                            <div onClick={() => history.push("/Barco")} className='menuBarBtn'>{t('MenuBar.Boat/Equipment')}</div>
                        </Grid>
                        <Grid item className='MenuBarButtonGridItem'>
                            <div onClick={() => history.push("/Marcacoes")} className='menuBarBtn'>{t('MenuBar.Trips')}</div>
                        </Grid>
                        <Grid item className='MenuBarButtonGridItem'>
                            <div onClick={() => history.push("/Colaboradores")} className='menuBarBtn'>{t('MenuBar.Sponsors')}</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <div className="buttons">
                        <IconButton className="InstaImgOut" size='small' onClick={() => window.open('https://www.instagram.com/mandrakefishing/')}>
                            <img src={imgInstagram} alt={"MissingImg"} className="InstaImgIn"></img>
                        </IconButton>
                        <IconButton className="FaceImgOut" size='small' onClick={() => window.open('https://www.facebook.com/mandrakefishing')}>
                            <img src={imgFacebook} alt={"MissingImg"} className="FaceImgIn"></img>
                        </IconButton>
                        <IconButton size='small' onClick={() => i18n.changeLanguage('pt')}>
                            <img src={PT} alt={"MissingImg"} className="PTImg"></img>
                        </IconButton>
                        <IconButton size='small' onClick={() => i18n.changeLanguage('en')}>
                            <img src={ENG} alt={"MissingImg"} className="ENGImg"></img>
                        </IconButton>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default MenuBar;