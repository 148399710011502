import i18next from "i18next";

import common_en from "./Translations/en/common.json";
import common_pt from "./Translations/pt/common.json";

i18next.init({
    lng: 'pt',
    returnObjects: true,
    resources: {
        en: {
            common: common_en
        },
        pt: {
            common: common_pt
        },
    },
});

export default i18next;