import './Contactos.css'
import TopBar from '../Componentes/TopBar'
import MenuBar from '../Componentes/MenuBar'
import BottomBar from '../Componentes/BottomBar'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import { send } from 'emailjs-com';
import { useState } from 'react'

import { useTranslation } from "react-i18next";

const Contactos = () => {
    const { t } = useTranslation('common');
    const [Nome, setNome] = useState('');
    const [Mail, setMail] = useState('');
    const [Empresa, setEmpresa] = useState('');
    const [Mensagem, setMensagem] = useState('');
    const [Contacto, setContacto] = useState('');
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [NameEmpty, setNameEmpty] = useState(true);
    const [MailEmpty, setMailEmpty] = useState(true);
    const [MsgEmpty, setMsgEmpty] = useState(true);
    const [BtnDisabled, setBtnDisabled] = useState(true);
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

    const handleNomeChange = (e) => {
        if (e === '') {
            setNameEmpty(true)
            setBtnDisabled(true)
        } else {
            setNameEmpty(false)
            if (!MailEmpty && !MsgEmpty) {
                setBtnDisabled(false)
            }
        }
        setNome(e);
    }
    const handleMailChange = (e) => {
        if (e.target.value === '' || !validEmail.test(e.target.value)) {
            setMailEmpty(true)
            setBtnDisabled(true)
        } else {
            setMailEmpty(false)
            if (!NameEmpty && !MsgEmpty) {
                setBtnDisabled(false)
            }
        }
        setMail(e.target.value);
    }
    const handleMsgChange = (e) => {
        if (e === '') {
            setMsgEmpty(true)
            setBtnDisabled(true)
        } else {
            setMsgEmpty(false)
            if (!NameEmpty && !MailEmpty) {
                setBtnDisabled(false)
            }
        }
        setMensagem(e);
    }

    const enviar = (e) => {
        const tempParams = {
            Nome: Nome,
            Empresa: Empresa,
            From: Mail,
            Contacto: Contacto,
            Mensagem: Mensagem
        }
        send(
            process.env.REACT_APP_SERVICE,
            process.env.REACT_APP_TEMPLATE,
            tempParams,
            process.env.REACT_APP_USER
        ).then((response) => {
            setOpen1(true)
        }).catch((err) => {
            setOpen2(true)
        })
    }

    return (
        <div className="MainDiv">
            <TopBar />
            <MenuBar />
            <Collapse in={open1}>
                <Alert
                    variant="filled"
                    severity="success"
                    action={
                        <IconButton onClick={() => setOpen1(!open1)}><CloseIcon /></IconButton>
                    }>
                    <Typography variant="h6">
                        {t('Contact.AlertText1')}
                    </Typography>
                </Alert>
            </Collapse>
            <Collapse in={open2}>
                <Alert
                    variant="filled"
                    severity="error"
                    action={
                        <IconButton onClick={() => setOpen2(!open2)}><CloseIcon /></IconButton>
                    }>
                    <Typography variant="h6">
                        {t('Contact.AlertText2')}
                    </Typography>
                </Alert>
            </Collapse>
            <div className='ContactosDivOut'>
                <div className='ContactosDiv'>
                    <TextField error={NameEmpty} className='ContactosFields' label={t('Contact.Name')} value={Nome} required onChange={(evt) => handleNomeChange(evt.target.value)} />
                    <TextField error={MailEmpty} className='ContactosFields' type="email" label='Mail' value={Mail} required onChange={(evt) => handleMailChange(evt)} />
                    <TextField className='ContactosFields' label={t('Contact.Company')} value={Empresa} onChange={(evt) => setEmpresa(evt.target.value)} />
                    <TextField className='ContactosFields' type="number" label={t('Contact.Phone')} value={Contacto} onChange={(evt) => setContacto(evt.target.value)} />
                    <TextField error={MsgEmpty} className='ContactosFields' label={t('Contact.Message')} multiline required rows={15} value={Mensagem} onChange={(evt) => handleMsgChange(evt.target.value)} />
                    <Button disabled={BtnDisabled} variant='outlined' className='ContactosBtn' onClick={enviar}>{t('Contact.SendButton')}</Button>
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Contactos;